import React, {FunctionComponent as FC, useContext, useEffect, useRef, useState} from "react";
import styled from "styled-components";
import {LanguageContext} from "../../provider/language";
import Divider from "../divider";
import Error from "../error";
import Loading from "../loading";
import {Markdown} from "../markdown";
// @ts-ignore
import ChevronIcon from "./chevron_right.svg";
import {StringParam, useQueryParam} from "use-query-params";

interface Selectable {
  isSelected: boolean;
}

interface Expandable {
  isExpanded: boolean;
  clientHeight?: number;
  questionHeight?: number;
}

const StyledRow = styled.div<Expandable>`
  position: relative;
  min-height: "50px";
  transition: all 200ms;
  max-height: ${(props) =>
    props.isExpanded
      ? `${props.clientHeight + 16}px`
      : `${props.questionHeight + 32}px`};
  &:hover {
    background: rgba(0, 0, 0, 0.1);
    cursor: pointer;
  }
  overflow: hidden;

  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    max-height: ${(props) =>
      props.isExpanded
        ? `${props.clientHeight + props.questionHeight + 16}px`
        : `${props.questionHeight + 32}px`};
  }
`;

const StyledIcon = styled.img<Selectable>`
  position: absolute;
  right: 10px;
  top: 16px;
  transform: rotate(0deg);
  overflow: hidden;
  transition: all 0.15s ease-out;
  transform: ${(props) => (props.isSelected ? `rotate(90deg)` : "")};
`;

const StyledAnswer = styled.div<Expandable>`
  transition: all 200ms;
  opacity: ${(props) => (props.isExpanded ? "1.0" : "0.0")};
`;

const FAQs: FC<{location: any; faqs: any; loading: boolean; error: any, dividerColor?: string}> = ({
  location,
  faqs,
  loading,
  error,
  dividerColor
}) => {
  const {locale} = useContext(LanguageContext);
  const answerRefs = [];
  const questionRefs = [];
  const [expanded, setExpanded] = useState<number>(null);

  // Trigger rerender to apply right height to FAQs
  const [dummy, setDummy] = useState<boolean>(null);
  useEffect(() => {
    setDummy(!dummy);
  }, [locale]);

  const [itemKeyRaw] = useQueryParam("itemId", StringParam);
  const [referenceKey] = useQueryParam("reference", StringParam);

  useEffect(() => {
    if (itemKeyRaw || referenceKey) {
      const itemKey = parseInt(itemKeyRaw);
      const idx = faqs.findIndex((faq) => {
        return (itemKey ? faq.sortKey === itemKey : false) || (referenceKey? faq.reference === referenceKey : false);
      });

      // Scroll to index if found.
      if (questionRefs.length && idx >= 0) {
        setTimeout(() => {
          questionRefs[idx].current.scrollIntoView({
            inline: "center"
          });
        }, 300);
      }

      // Expand item on index.
      setExpanded(idx);
    }
  }, [faqs, location]);

  if ((loading || !faqs) && !error) {
    return <Loading />;
  }

  if (error) {
    return <Error error={error} />;
  }

  return (
    <div id="accordion">
      {faqs.map((faq, i) => {
        answerRefs.push(useRef(null));
        questionRefs.push(useRef(null));
        return (
          <div key={i}>
            <div className="container">
              <StyledRow
                className="row py-3"
                onClick={() => setExpanded(expanded == i ? null : i)}
                isExpanded={i === expanded}
                clientHeight={
                  answerRefs[i].current && answerRefs[i].current.clientHeight
                }
                questionHeight={
                  questionRefs[i].current &&
                  questionRefs[i].current.clientHeight
                }
              >
                <div className="col-12 col-md-6">
                  <div ref={questionRefs[i]}>{faq.title[locale]}</div>
                </div>
                <StyledAnswer
                  className={"col-12 col-md-5"}
                  isExpanded={i === expanded}
                  ref={answerRefs[i]}
                >
                  <div className="mt-3 mt-md-0">
                    <Markdown
                      content={faq.content.markdown[locale]}
                      enableTargetBlank
                    />
                  </div>
                </StyledAnswer>
                <StyledIcon isSelected={expanded === i} src={ChevronIcon} />
              </StyledRow>
            </div>
            <Divider isSelected={expanded === i} margin={false} color={dividerColor}/>
          </div>
        );
      })}
    </div>
  );
};

export default FAQs;
